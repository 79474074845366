<template>
  <b-card no-body>
    <b-container fluid class="p-2 pb-0 mb-0">
      <b-row class="p-0 mb-0" align-v="center">
        <b-col cols="auto" class="mr-auto pl-1">
          <h1 class="text-primary font-weight-bolder" style="font-size: 2rem">Notifikasi</h1> 
        </b-col>
      </b-row>
    </b-container>
    <hr/>

    <b-card-body class="pl-3 pr-3 mt-1 pt-0">      
      <b-card-text>
        Notifikasi SmartFarm:
      </b-card-text>
      <b-table
        responsive
        :items="items"
        class="mb-0"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import { BTable, BImg,BRow, BCol, BCard, BContainer, BCardBody, BCardText } from 'bootstrap-vue'

import axios from '@axios'
import { onMounted } from '@vue/composition-api'

export default {
  components: {
    BTable,
    BImg,
    BCard,
    BContainer,
    BCardBody,
    BCardText,
    BRow,
    BCol,
  },
  setup() {
    const notifications = ref([]);

    const getAllNotifications = async () => {
      await axios.get('notifications').then((response) => {
        const { data } = response.data
        const notificationData = []
        data.forEach((notification) => {
          notificationData.push(notification);
        })

        notifications.value = notificationData;
      })
    }

    const updateAllNotificationReadStatus = async () => {
      await axios.put('notifications').then((response) => {

      })
    }

    onMounted(() =>{
      getAllNotifications()
    })

    return {
      notifications,
    }
  }
}

</script>

<style>

</style>
